<template>
    <div class="admin_page_body">
        <div class="admin_page_item">
            <div class="data_table">
                <div class="order_body_header" style="height: auto; padding: 16px; flex-direction: column">
                    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%">
                        <div class="info">
                            <div class="text">{{dataInfo.createdAt}}</div>
                            <div class="text ml16">订单编号：{{dataInfo.detailCode}}</div>
                            <a href="javascript:void(0)" class="ml16" @click="isPopupLog = true">更多</a>
                        </div>
                        <div class="text">订单状态：
                            <template v-if="dataInfo.status === 1">待付款</template>
                            <template v-if="dataInfo.status === 2">待发货</template>
                            <template v-if="dataInfo.status === 3">已发货</template>
                            <template v-if="dataInfo.status === 4">已完成</template>
                            <template v-if="dataInfo.status === 5">已取消</template>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%" class="mt18" v-if="dataInfo.contractCode || dataInfo.logisticsName">
                        <div class="info">
                            <div class="text" v-if="dataInfo.contractCode">合同：{{dataInfo.contractCode}} <a href="javascript:void(0)" v-if="contractUrl.length > 0" @click="dataInfo.contractType === 1 ? toShowImage(contractUrl) : toShowFile('合同查询', contractUrl)">(合同查询)</a></div>
                        </div>
                        <div class="text" v-if="dataInfo.logisticsName">物流信息：{{dataInfo.logisticsName}} | {{dataInfo.logisticsCode}} <a href="javascript:void(0)" v-if="logisticsUrl.length > 0" @click="dataInfo.logisticsType === 1 ? toShowImage(logisticsUrl) : toShowFile('物流凭证查询', logisticsUrl)">(物流凭证查询)</a></div>
                    </div>
                </div>
            </div>

            <div class="data_detail mt32">
                    <el-form ref="listQuery" :inline="true" :model="listQuery" size="small" label-position="top">
                        <el-form-item label="买家账号：">
                            <span class="text">{{dataInfo.userAccount}}</span>
                        </el-form-item>

<!--                        <el-form-item label="仓库：">-->
<!--                            <span class="text">{{dataInfo.userAccount}}</span>-->
<!--                        </el-form-item>-->

                        <el-form-item label="店铺：" v-if="dataInfo.warehouseName">
                            <span class="text">{{dataInfo.warehouseName}}</span>
                        </el-form-item>

                        <el-form-item label="收货人：">
                            <span class="text">{{dataInfo.channel === 2 ? '店铺自提' : dataInfo.userName}}</span>
                        </el-form-item>

<!--                        <el-form-item label="商品/货号：">-->
<!--                            <span class="text">{{dataInfo.userAccount}}</span>-->
<!--                        </el-form-item>-->

                        <el-form-item label="收件人电话：">
                            <span class="text">{{dataInfo.channel === 2 ? '--' : dataInfo.userPhone}}</span>
                        </el-form-item>

                        <el-form-item label="收货地址：">
                            <span class="text">{{dataInfo.channel === 2 ? '--' : dataInfo.userProvince + dataInfo.userCity + dataInfo.userArea + dataInfo.userAddress}}</span>
                        </el-form-item>

                        <el-form-item label="发货方式：">
                            <span class="text" v-if="dataInfo.sendType === 1">线下发货</span>
                            <span class="text" v-if="dataInfo.sendType === 2">仓储发货</span>
                            <span class="text" v-if="dataInfo.sendType === 3">货权交割</span>
                        </el-form-item>

                        <el-form-item label="配送方式：">
                            <span class="text" v-if="dataInfo.deliveryType === 1">物流配送</span>
                            <span class="text" v-if="dataInfo.deliveryType === 2">店面自提</span>
                        </el-form-item>

                        <el-form-item label="商品数量：">
                            <span class="text">{{dataInfo.num}}</span>
                        </el-form-item>

                        <el-form-item label="单据号：" v-if="dataInfo.receiptCode">
                            <span class="text">{{dataInfo.receiptCode}}</span>
                        </el-form-item>

                        <el-form-item label="支付方式：">
                            <span class="text" v-if="dataInfo.payWay === 1">直接打款</span>
                            <span class="text" v-if="dataInfo.payWay === 2">线下支付</span>
                            <span class="text" v-if="dataInfo.payWay === 3">担保支付</span>
                        </el-form-item>

                        <el-form-item label="支付类型：" v-if="dataInfo.payWay === 1 || dataInfo.payWay === 3">
                            <span class="text">{{getPayTypeName(dataInfo.payType)}}</span>
                        </el-form-item>

                        <el-form-item label="支付单号：" v-if="dataInfo.payWay === 1 || dataInfo.payWay === 3">
                            <span class="text">{{dataInfo.payCode}}</span>
                        </el-form-item>

                        <el-form-item label="买家留言：">
                            <span class="text">{{dataInfo.remark}}</span>
                        </el-form-item>
                    </el-form>
            </div>
        </div>

        <div class="admin_page_item mt24">
            <div class="data_table pr0">
                <div class="orders_list">
                    <div class="orders_header">
                        <div class="item" style="flex: 3"><span>商品</span></div>
                        <div class="item" style="flex: 1; text-align: center"><span>单价</span></div>
                        <div class="item" style="flex: 1; text-align: center"><span>数量</span></div>
                    </div>

                    <div class="order_body">
                        <div class="order_body_item">
                            <div class="body" style="display: block">
                                <div class="item" style="flex: 5" v-for="(item, index) in dataInfo.goodsInfo" :key="index">
                                    <div class="goods_list">
                                        <div class="goods_list_item" style="flex: 3">
                                            <div class="goods_item">
                                                <el-image :src="imageURL + item.goodsImageUrl" class="image" fit="contain" @click="$router.push({path: '/shop/detail', query: {id: item.goodsId}})"></el-image>
                                                <div class="goods_item_info">
                                                    <div class="name" @click="$router.push({path: '/shop/detail', query: {id: item.goodsId}})">{{item.goodsName}}</div>
                                                    <div class="text">
                                                        <span class="mr16" v-for="(sku, j) in item.sku" :key="j">{{sku.skuKey}}：{{sku.skuValue}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="goods_list_item" style="flex: 1; text-align: center">
                                            <span class="desc">￥{{item.price}}</span>
                                        </div>
                                        <div class="goods_list_item" style="flex: 1; text-align: center">
                                            <span class="desc">×{{item.num}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        <div class="admin_page_item mt24">-->
<!--            <h1 class="tableTitle">物流信息</h1>-->
<!--            <el-table :data="list" class="order_table mt14">-->
<!--                <el-table-column prop="name" label="物流公司" min-width="160px"></el-table-column>-->
<!--                <el-table-column prop="name" label="物流单号" min-width="160px"></el-table-column>-->
<!--                <el-table-column prop="name" label="商品/数量" min-width="240px"></el-table-column>-->
<!--                <el-table-column prop="name" label="发货时间" min-width="160px"></el-table-column>-->

<!--                <el-table-column prop="address" label="操作" align="center" width="140">-->
<!--                    <template>-->
<!--                        <el-button type="text">编辑发货</el-button>-->
<!--                        <el-button type="text">详情</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--            </el-table>-->

<!--            <div class="orderTableBottomBtn">-->
<!--                <el-button type="primary" size="small">订单发货</el-button>-->
<!--            </div>-->
<!--        </div>-->

        <div class="admin_page_item mt24" v-if="dataInfo.payWay === 1 && (dataInfo.status === 2 || dataInfo.status === 3 || dataInfo.status === 4)">
            <h1 class="tableTitle">支付单</h1>
            <el-table :data="payList" class="order_table mt14">
                <el-table-column prop="payType" label="支付类型" min-width="120px"></el-table-column>
                <el-table-column prop="paySn" label="支付单号" min-width="120px"></el-table-column>
                <el-table-column prop="amount" label="支付金额" min-width="120px"></el-table-column>
                <el-table-column prop="payTime" label="支付时间" min-width="160px"></el-table-column>
                <el-table-column prop="name" label="支付状态" min-width="120px">
                    <template slot-scope="scope">
                        <div class="status green" v-if="scope.row.status === -2">短信发送成功</div>
                        <div class="status red" v-if="scope.row.status === -1">失败</div>
                        <div class="status info" v-if="scope.row.status === 0">初始化</div>
                        <div class="status green" v-if="scope.row.status === 1">成功</div>
                        <div class="status yellow" v-if="scope.row.status === 2">处理中</div>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="name" label="结算状态" min-width="120px"></el-table-column>-->
<!--                <el-table-column prop="name" label="主动退款金额（已退还金额）" min-width="210px"></el-table-column>-->

<!--                <el-table-column prop="address" label="操作" align="center" width="160">-->
<!--                    <template>-->
<!--                        <el-button type="text" @click="isPopupRefund = true">主动退款</el-button>-->
<!--                        <el-button type="text" @click="isPopupRefundCheck = true">操作日志</el-button>-->
<!--                    </template>-->
<!--                </el-table-column>-->
            </el-table>
        </div>

<!--        <div class="admin_page_item mt24">-->
<!--            <h1 class="tableTitle">支付单</h1>-->
<!--            <el-table :data="list" class="order_table mt14">-->
<!--                <el-table-column prop="name" label="支付类型" min-width="120px"></el-table-column>-->
<!--                <el-table-column prop="name" label="支付单号" min-width="120px"></el-table-column>-->
<!--                <el-table-column prop="name" label="支付凭证">-->
<!--                    <template>-->
<!--                        <el-image-->
<!--                                style="width: 40px; height: 40px"-->
<!--                                src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"-->
<!--                                :preview-src-list="['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']">-->
<!--                        </el-image>-->
<!--                        <el-image-->
<!--                                style="width: 40px; height: 40px" class="ml10"-->
<!--                                src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"-->
<!--                                :preview-src-list="['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']">-->
<!--                        </el-image>-->
<!--                    </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column prop="name" label="支付时间" min-width="160px"></el-table-column>-->
<!--            </el-table>-->
<!--        </div>-->

        <div class="order_detail_footer mt24">
            <div class="info">
                <div class="Price">
                    <div class="desc">订单应付金额</div>
                    <div class="text">￥{{dataInfo.price}}</div>
                </div>
                <div class="subText" v-if="dataInfo.status === 2 || dataInfo.status === 3 || dataInfo.status === 4">全额付款</div>
                <template v-if="dataInfo.channel === 1">
                    <el-button type="text" v-if="dataInfo.payWay === 2 && (dataInfo.status === 2 || dataInfo.status === 3 || dataInfo.status === 4)" @click="toShowImage(url)">（付款凭证查询）</el-button>
                    <el-button type="text" v-if="invoiceUrl.length > 0" @click="dataInfo.invoiceType === 1 ? toShowImage(invoiceUrl) : toShowFile('发票查询', invoiceUrl)">（发票查询）</el-button>
                </template>
            </div>
            <el-button type="default" size="small" @click="$router.go(-1)">返 回</el-button>
        </div>

        <imageViewer v-if="showViewer" :on-close="closeViewer" :url-list="showImageList" />
        <filePopup :isFilePopup="isFilePopup" :fileTitle="fileTitle" :fileList="fileList" @closePopup="isFilePopup = false" />

        <el-dialog class="popup_dialog" title="订单日志" :visible.sync="isPopupLog" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="800px">
            <el-table ref="goodsFormatData" :data="orderLog" style="width: 100%" class="addGoodsChangeFormatTable">
                <el-table-column prop="logTime" label="操作时间"></el-table-column>
                <el-table-column prop="status" label="操作内容"></el-table-column>
            </el-table>

            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isPopupLog = false" size="small">关 闭</el-button>
            </div>
        </el-dialog>

        <el-dialog class="popup_dialog" title="主动退款" :visible.sync="isPopupRefund" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="760px">
            <el-form ref="formSend" :model="formSend" size="small" label-position="left" :rules="rulesSend" label-width="80px">
                <el-form-item label="支付单：">
                    <span>2021-06-02 15:45 27272728（¥222.70）</span>
                </el-form-item>

                <el-form-item label="退款原因：">
                    <span>主动退款</span>
                </el-form-item>

                <el-form-item label="退款金额：">
                    <el-input v-model="formSend.logisticsCode" autocomplete="off" placeholder="金额" style="width: 100px"></el-input>

                    <span class="ml10">元</span>
                    <span class="ml16"> （最多<span style="color: #529804">¥195.90</span>）</span>
                </el-form-item>

                <el-form-item label="退款说明：">
                    <el-input v-model="formSend.logisticsCode" autocomplete="off" type="textarea" :rows="4" placeholder="请输入退款说明"></el-input>
                </el-form-item>

                <el-form-item label="上传凭证：">
                    <el-upload
                            ref="uploadLogisticsImg"
                            class="avatar-uploader"
                            list-type="picture-card"
                            :file-list="formSend.imageFileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading">提  交</el-button>
                    <el-button @click="isPopupRefund = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog class="popup_dialog" title="审核" :visible.sync="isPopupRefundCheck" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="760px">
            <el-form ref="formSend" :model="formSend" size="small" label-position="left" :rules="rulesSend" label-width="80px">
                <el-form-item label="退款单号：">
                    <span>R2021908048794</span>
                </el-form-item>

                <el-form-item label="支付单：">
                    <span>2021-06-02 15:45 27272728（¥222.70）</span>
                </el-form-item>

                <el-form-item label="退款原因：">
                    <span>主动退款</span>
                </el-form-item>

                <el-form-item label="退款金额：">
                    <span style="color: #529804">¥195.90</span>
                </el-form-item>

                <el-form-item label="退款说明：">
                    <span>¥195.90</span>
                </el-form-item>

                <el-form-item label="上传凭证：">
                    <el-image
                            style="width: 40px; height: 40px"
                            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                            :preview-src-list="['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']">
                    </el-image>
                    <el-image
                            style="width: 40px; height: 40px" class="ml10"
                            src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                            :preview-src-list="['https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg']">
                    </el-image>
                </el-form-item>

                <el-form-item label="审核状态：" prop="logisticsType">
                    <el-radio-group v-model="formSend.logisticsType">
                        <el-radio :label="1">拒绝</el-radio>
                        <el-radio :label="2">同意</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="审核意见：" prop="logisticsCode">
                    <el-input v-model="formSend.logisticsCode" type="textarea" :row="4" placeholder="请输入审核意见"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="isSubmitLoading">提  交</el-button>
                    <el-button @click="isPopupRefundCheck = false">关  闭</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import {imageURL} from "../../../utils/config";
import {getAdminOrderInfo, getOrdersPay} from "../../../api/admin/orders";
import {getLogs} from "../../../api/shop/orders";
const filePopup = () => import('../../../components/filePopup')
const pagination = () => import('../../../components/pagination/shop')
const imageViewer = () => import('../../../components/imageViewer')

export default {
  name: "adminOrderDetail",
  data () {
    return {
      isRequestLoading: false,
      dataInfo: {},
      imageURL: imageURL,
      url: [],
      invoiceUrl: [],
      logisticsUrl: [],
      contractUrl: [],
      showImageList: [],
      isFilePopup: false,
      fileTitle: '',
      fileList: [],
      isPopupLog: false,
      orderLog: [],
      listQuery: {
        page: 1,
        pageSize: 5
      },
      pages: {
        total: 0,
        pages: 0
      },
      listData: [],
      showViewer: false,
      prevOverflow: '',
      list: [{name: '顺丰快递'}],
      isPopupRefund: false,
      formSend: {
        id: null,
        logisticsId: null,
        logisticsCode: '',
        logisticsType: 1,
        logisticsUrl: [],
        type: 1,
        status: 1,
        desc: '',
        imageFileList: []
      },
      rulesSend: {
        logisticsId: [
          {required: true, message: '请选择物流公司', trigger: 'change'}
        ],
        logisticsCode: [
          {required: true, message: '请输入物流单号', trigger: 'blur'}
        ],
      },
      isPopupRefundCheck: false,
      payList: []
    }
  },
  created() {
    this.$store.commit('changeScrollTop');
    this.getInfo();
    this.getOrdersPayInfo();
    this.getLogs()
  },
  methods: {
    getInfo () {
      this.isRequestLoading = true
      getAdminOrderInfo(this.$route.query.id).then(res => {
        this.isRequestLoading = false
        if (res.code === 0) {
          this.dataInfo = res.data

          let url = []
          if (res.data.url !== null) {
            res.data.url.forEach(item => {
              url.push(this.imageURL + item)
            })
          }
          this.url = url

          let invoiceUrl = []
          if (res.data.invoiceType === 1 && res.data.invoiceUrl) {
            res.data.invoiceUrl.forEach(item => {
              invoiceUrl.push(this.imageURL + item.url)
            })
            this.invoiceUrl = invoiceUrl
          }
          if (res.data.invoiceType === 2 && res.data.invoiceUrl) {
            this.invoiceUrl = res.data.invoiceUrl
          }

          let logisticsUrl = []
          if (res.data.logisticsType === 1 && res.data.logisticsUrl) {
            JSON.parse(res.data.logisticsUrl).forEach(item => {
              logisticsUrl.push(this.imageURL + item.url)
            })
            this.logisticsUrl = logisticsUrl
          }
          if (res.data.logisticsType === 2 && res.data.logisticsUrl) {
            this.logisticsUrl = JSON.parse(res.data.logisticsUrl)
          }

          let contractUrl = []
          if (res.data.contractType === 1 && res.data.contractUrl) {
            res.data.contractUrl.forEach(item => {
              contractUrl.push(this.imageURL + item.url)
            })
            this.contractUrl = contractUrl
          }
          if (res.data.contractType === 2 && res.data.contractUrl) {
            this.contractUrl = res.data.contractUrl
          }
        }
      }).catch(() => {
        this.isRequestLoading = false
      })
    },
    getOrdersPayInfo () {
      getOrdersPay(this.$route.query.id).then(res => {
        if (res.code === 0) {
          this.payList = res.data.payList
        }
      })
    },
    toShowImage(list) {
      this.showImageList = list
      this.prevOverflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      this.showViewer = true
    },
    closeViewer () {
      document.body.style.overflow = this.prevOverflow;
      this.showViewer = false
    },
    toShowFile (title, list) {
      this.isFilePopup = true
      this.fileTitle = title
      this.fileList = list
    },
    getLogs () {
      getLogs({
        detailId: this.$route.query.id,
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize
      }).then(res => {
        if (res.code === 0) {
          this.orderLog = res.data.list
          this.pages.total = res.data.total
          this.pages.pages = res.data.pages
        }
      })
    },
    pageChange (e) {
      this.listQuery.page = e.current
      this.listQuery.pageSize = e.size
      this.getLogs()
    },
    getPayTypeName (payType) {
      let name = '';
      let type = Number(payType);
      switch (type) {
        case 1:
          name = '个人网银支付';
          break;
        case 2:
          name = '企业网银支付';
          break;
        case 3:
          name = '快捷支付';
          break;
        case 4:
          name = '微信app支付';
          break;
        case 5:
          name = '支付宝app支付';
          break;
        case 6:
          name = '微信扫码支付';
          break;
        case 9:
          name = '支付宝扫码支付';
          break;
      }
      return name;
    },
  },
  components: {filePopup, pagination, imageViewer}
}
</script>
